import { ref } from 'vue'
import { defineStore } from 'pinia'
export const gisMapStore = defineStore('gismap', () => {
  const selectSpeciesList=ref([])  //选择面板  
  const offlineTabInfo=ref([])  //离线tab标签信息  
  const flage=ref(true)  //用于显示上传组件
  const flage01=ref(true) //用于显示上传匹配组件
  const countcol = ref(0)  //统计文件的数量
  const countrow = ref(0)//统计文件的行数量
  const orglength = ref(0)//原始列名数量
  const stdlength = ref(0)//匹配到的标准列名数量
  const orgname = ref([])//原始列名
  const stdname = ref([])//匹配到的列名
  const filename = ref('')//文件名称
  const fileType=ref('')//文件类型
  const responseTime=ref('')//返回的时间
  const stdnamecol=ref([])
  const speciesList=ref() //后端物种名称列表
  const fullUrl=ref('')
  const fileStartDateTime=ref()
  const fileEndDateTime=ref()
  const handleCsvDataObj= ref()
  const uniqueCities =ref()//站点名称
  const seasonsData = ref() // 返回的四个季节
  const monthlyData =ref() //年份加月份归类
  const calendarMonth=ref() //日历图的月份
  function $reset(){
    if(speciesList.value){
      speciesList.value.splice(0, speciesList.value.length);
    }
    if(stdname.value){
      stdname.value.splice(0, stdname.value.length);
    }
    if(orgname.value){
      orgname.value.splice(0, orgname.value.length);
    }
  }
  function setcalendarMonth(newvalue){
        calendarMonth.value=newvalue
      }
  function setfileStartDateTime(newvalue){
    fileStartDateTime.value=newvalue
  }
  function setfileEndDateTime(newvalue){
    fileEndDateTime.value=newvalue
  }
  function setSpeciesList(newvalue){
    speciesList.value=newvalue
  }
  function setOfflineTabInfo(newvalue){
    offlineTabInfo.value.push(newvalue)
  }
  function setFlage01(newvalue){
    flage01.value=newvalue
  }
  function setFullUrl(newvalue){
    fullUrl.value=newvalue
  }
  function setStdnameCol(newvalue){
    stdnamecol.value=newvalue
  }
  function setfileType(newvalue){
    fileType.value=newvalue
  }
  function setresponseTime(newvalue){
    responseTime.value=newvalue
  }
  function setFlage(newvalue){
    flage.value=newvalue
  }
  function setCountcol(newvalue){
    countcol.value=newvalue
  }
  function setCountraw(newvalue){
    countrow.value=newvalue
  }
  function setOrgLength(newvalue){
    orglength.value=newvalue
  }
  function setStdLength(newvalue){
    stdlength.value=newvalue
  }
  function setOrgName(newvalue){
    orgname.value=newvalue
  }
  function setStdName(newvalue){
    stdname.value=newvalue
  }
  function setFileName(newvalue){
    filename.value=newvalue
  }
  function sethandleCsvDataObj(newvalue){
    handleCsvDataObj.value= newvalue
  }
  function setseasonsData(newvalue){
    seasonsData.value = newvalue
  }
  function setmonthlyData(newvalue){
    monthlyData.value =newvalue
  }
  function setuniqueCities(newvalue){
    uniqueCities.value =newvalue
  }
  function increment() {
    countcol.value++
  }
  function setselectSpeciesList(newvalue){
    selectSpeciesList.value=newvalue
  }
  return {speciesList,flage01,fullUrl,stdnamecol,fileType,responseTime,monthlyData,seasonsData,handleCsvDataObj,
    flage,countcol, countrow,orglength,uniqueCities,stdlength,orgname,stdname,filename,fileStartDateTime,
    fileEndDateTime,calendarMonth,offlineTabInfo,selectSpeciesList,
    setOfflineTabInfo,setfileStartDateTime,setfileEndDateTime,
    increment,setFlage,setCountcol,setCountraw,setOrgLength,setStdLength,setseasonsData,setmonthlyData,sethandleCsvDataObj,setselectSpeciesList,
    setOrgName,setStdName,setuniqueCities,setFileName,setfileType,setresponseTime,setStdnameCol,setFullUrl,setFlage01,setSpeciesList,setcalendarMonth,$reset}
})
