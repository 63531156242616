import { createRouter, createWebHistory } from 'vue-router'
import Breadcrumb from '@/components/common/breadcrumb.vue'
import NotFound from '@/components/common/NotFound.vue' //404

// 详情
const routes = [
  {
    path: '/logon',
    redirect: '/',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/',
    name: 'logon',
    component: () => import('../views/login/logon.vue')
  },
  {
    path: '/home',//布局页面
    name: 'home',
    component: Breadcrumb,
    meta: { tabName: '首页' },
    component: () => import('../views/home/index.vue'),
    children: [
      {
        path: '/Alllist/:id*',//默认首页显示所有数据
        name: '首页',
        meta: { tabName: '首页' },
        component: () => import('@/views/home/index/Alllist.vue')
      },
      {
        path: '/01_heatmap/:id*',//01热图详情
        name: '热图',
        meta: { tabName: '首页' },
        component: () => import('../views/home/index/01_heatmap.vue')
      },
      {
        path: '/02_rose/:id*',//02玫瑰图详情
        name: '玫瑰图',
        meta: { tabName: '玫瑰图' },
        component: () => import('../views/home/index/02_rose.vue')
      },
      {
        path: '/02_rose1/:id*',//02玫瑰图详情
        name: '玫瑰矩阵图',
        meta: { tabName: '玫瑰矩阵图' },
        component: () => import('../views/home/index/02_rose1.vue')
      },
      {
        path: '/03_Gis/:id*',//03Gis图详情
        name: 'Gis图',
        meta: { tabName: 'Gis图' },
        component: () => import('../views/home/index/03_Gismap.vue')
      },
      {
        path: '/04_corrmap/:id*',//04相关性图
        name: '相关性',
        meta: { tabName: '相关性图' },
        component: () => import('../views/home/index/04_corrmap.vue')
      },
      {
        path: '/05_Matrix/:id*',//05矩阵图
        name: '矩阵图',
        meta: { tabName: '矩阵图' },
        component: () => import('../views/home/index/05_Matrix.vue')
      },
      {
        path: '/06_voctopnmap/:id*',//06贡献排名
        name: '贡献排名',
        meta: { tabName: '贡献排名' },
        component: () => import('../views/home/index/06_voctopnmap.vue')
      },
      {
        path: '/07_vocpiemap/:id*',//07饼图
        name: '饼图',
        meta: { tabName: '饼图' },
        component: () => import('../views/home/index/07_vocpiemap.vue')
      },
      {
        path: '/08_vocdailymap/:id*',//08VOCs浓度逐日图
        name: 'VOCs',
        meta: { tabName: 'VOCs浓度逐日图' },
        component: () => import('../views/home/index/08_vocdailymap.vue')
      },
      {
        path: '/09_vochourmap/:id*',//09面积日变化浓度
        name: '面积日变化',
        meta: { tabName: '面积日变化浓度' },
        component: () => import('../views/home/index/09_vochourmap.vue')
      },
      {
        path: '/10_voccontritable/:id*',//10VOC组分及其贡献比
        name: 'VOC组分',
        meta: { tabName: 'VOC组分及其贡献比' },
        component: () => import('../views/home/index/10_voccontritable.vue')
      },
      {
        path: '/11_vocboxmap/:id*',//11VOCs箱线图
        name: 'VOCs箱线图',
        meta: { tabName: 'VOCs箱线图' },
        component: () => import('../views/home/index/11_vocboxmap.vue')
      },
      {
        path: '/12_voccontritable_i/:id*',//12SOA生成贡献表
        name: 'SOA生成贡献表',
        meta: { tabName: 'SOA生成贡献表' },
        component: () => import('../views/home/index/12_voccontritable_i.vue')
      },
      {
        path: '/13_timeztmap/:id*',//12SOA生成贡献表
        name: '时间序列图',
        meta: { tabName: '时间序列图' },
        component: () => import('../views/home/index/13_timeztmap.vue')
      },
      {
        path: '/14_ribianhua/:id*',//日变化图
        name: '日变化图',
        meta: { tabName: '日变化图' },
        component: () => import('../views/home/index/14_ribianhua.vue')
      },
      {
        path: '/UploadFiles_Details/:id*',//报告下载
        name: '离线制图',
        meta: { tabName: '离线制图' },
        component: () => import('../views/home/index/UploadFiles_Details.vue')
      },
      {
        path: '/ReportDow/:id*',//报告下载
        name: '报告下载',
        meta: { tabName: '报告生成下载' },
        component: () => import('../views/home/index/ReportDow.vue')
      },
      {
        path: '/1_heatmap/:id*',//离线热图
        name: '离线热图',
        meta: { tabName: '离线热图' },
        component: () => import('../views/home/offline/1_heatmap.vue')
      },
      {
        path: '/2_rose/:id*',//离线玫瑰图
        name: '离线玫瑰图',
        meta: { tabName: '离线玫瑰图' },
        component: () => import('../views/home/offline/2_rose.vue')
      },
      {
        path: '/2_rose1/:id*',//离线玫瑰图
        name: '离线玫瑰矩阵图',
        meta: { tabName: '离线玫瑰矩阵图' },
        component: () => import('../views/home/offline/2_rose1.vue')
      },
      {
        path: '/4_corrmap/:id*',//相关性拟合离线图
        name: '相关性拟合离线图',
        meta: { tabName: '相关性拟合离线图' },
        component: () => import('../views/home/offline/4_corrmap.vue')
      },
      {
        path: '/5_Matrix/:id*',//因子相关离线图
        name: '因子相关离线图',
        meta: { tabName: '因子相关离线图' },
        component: () => import('../views/home/offline/5_Matrix.vue')
      },
      {
        path: '/6_voctopnmap/:id*',//贡献排名离线图
        name: '贡献排名离线图',
        meta: { tabName: '贡献排名离线图' },
        component: () => import('../views/home/offline/6_voctopnmap.vue')
      },
      {
        path: '/7_vocpiemap/:id*',//占比离线图
        name: '占比离线图',
        meta: { tabName: '占比离线图' },
        component: () => import('../views/home/offline/7_vocpiemap.vue')
      },
      {
        path: '/8_vocdailymap/:id*',//日变化离线图
        name: '日变化',
        meta: { tabName: '日变化离线图' },
        component: () => import('../views/home/offline/8_vocdailymap.vue')
      },
      {
        path: '/9_vochourmap/:id*',//VOCs浓度日变化
        name: 'VOCs浓度日变化',
        meta: { tabName: 'VOCs浓度日变化' },
        component: () => import('../views/home/offline/9_vochourmap.vue')
      },
      {
        path: '/10_voccontritable2/:id*',//VOC组分及贡献比
        name: 'VOC组分及贡献比',
        meta: { tabName: 'VOC组分及贡献比' },
        component: () => import('../views/home/offline/10_voccontritable2.vue')
      },
      {
        path: '/11_vocboxmap2/:id*',//VOCs箱线离线图
        name: 'VOCs箱线离线图',
        meta: { tabName: 'VOCs箱线离线图' },
        component: () => import('../views/home/offline/11_vocboxmap2.vue')
      },
      {
        path: '/12_voccontritable_i2/:id*',//SOA生成贡献表
        name: 'SOA贡献离线表',
        meta: { tabName: 'SOA贡献离线表' },
        component: () => import('../views/home/offline/12_voccontritable_i2.vue')
      },
      {
        path: '/13_timeztmap2/:id*',//时间序列离线图
        name: '时间序列离线图',
        meta: { tabName: '时间序列离线图' },
        component: () => import('../views/home/offline/13_timeztmap2.vue')
      },
      {
        path: '/uploadData/:id*',//数据导入
        name: '数据导入',
        meta: { tabName: '数据导入' },
        component: () => import('../views/home/offlineData/uploadData.vue')
      },
      {
        path: '/14_ribianhua2/:id*',//离线日变化
        name: '离线日变化',
        meta: { tabName: '离线日变化' },
        component: () => import('../views/home/offline/14_ribianhua2.vue')
      },
      {
        path: '/15_ternarymap_i/:id*',//三元相
        name: '三元相',
        meta: { tabName: '三元相' },
        component: () => import('../views/home/index/15_ternarymap_i.vue')
      },
      {
        path: '/15_ternarymap_i2/:id*',//离线三元相
        name: '离线三元相',
        meta: { tabName: '离线三元相' },
        component: () => import('../views/home/offline/15_ternarymap_i2.vue')
      },
      {
        path: '/16_ratiomap_i/:id*',//比值
        name: '比值',
        meta: { tabName: '比值' },
        component: () => import('../views/home/index/16_ratiomap_i.vue')
      },
      {
        path: '/16_ratiomap_i2/:id*',//离线比值
        name: '离线比值',
        meta: { tabName: '离线比值' },
        component: () => import('../views/home/offline/16_ratiomap_i2.vue')
      },
      {
        path: '/17_rili2/:id*',//离线比值
        name: '污染日历',
        meta: { tabName: '污染日历' },
        component: () => import('../views/home/offline/17_rili2.vue')
      },
      {
        path: '/17_rili/:id*',//离线比值
        name: '离线污染日历',
        meta: { tabName: '离线污染日历' },
        component: () => import('../views/home/index/17_rili.vue')
      },
      {
        path: '/Developing/:id*',
        name: '尽情期待',
        component: () => import('../views/home/index/Developing.vue')
      }
    ]
  },
  {
    path: '/detail/:id', // 定义动态路由，:id 是动态部分
    name: 'Detail',
    component: () => import('../views/home/index/Detail.vue') // 假设你的详情页面组件是 Detail.vue
  },
  //404错误跳转路由
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token');
  if (token || to.path === '/' || to.path === '/login' || to.path === '/logon') {
    next();
  } else {
    next('/');
  }
})
