import storageClear from "@/utils/storageClear";
import router from "../router/index"

let lastTime = new Date().getTime();
let currentTime = new Date().getTime();
let timeOut = 100 * 60 * 1000; //设置超时时间: 30分钟

window.onload = function() {
  window.document.onmousedown = function() {
    sessionStorage.setItem("lastTime", new Date().getTime());
  };
};

function checkTimeout() {
  currentTime = new Date().getTime(); //更新当前时间
  lastTime = sessionStorage.getItem("lastTime");

  if (currentTime - lastTime > timeOut) {
    //判断是否超时

    // 清除storage的数据(登陆信息和token)
    storageClear.clear();
    // 跳到登陆页
    router.push("/");
  }
}

export default function() {
  /* 定时器 间隔30秒检测是否长时间未操作页面 */
  window.setInterval(checkTimeout, 30000);
}